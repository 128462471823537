/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export { wrapPageElement } from './src/apollo/wrap-page-element';
require('typeface-rubik');
require('typeface-open-sans');
